@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
.App-Container {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
}
.App {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.5s ease;
  margin-top: 0;
  /* background-color: red; */
}
.Privacy {
  text-align: left;
  padding: 5vw;
  flex-direction: column;

  display: flex;
  transition: all 0.5s ease;
}
.navContainer {
  /* background-color: #f2f2f7; */
  width: 100vw;
  height: 12vw;
  padding-top: 2vw;

  display: flex;
  align-items: center;
}

.logo {
  height: 110%;
  object-fit: contain;
  float: left;
  object-position: left;
  margin-left: 5%;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.navLinkCont ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.navLinkCont {
  text-align: right;
  margin-left: 52%;
}
.navLink {
  text-decoration: none;
  color: #462a7b;
  font-family: "Roboto", sans-serif;
  font-size: 2vw;
  font-weight: 500;
}
.navLink:hover {
  color: black;
}
.profile {
  display: flex;
  justify-content: center;

  padding: 20px;
  margin: 10px;
  max-width: 500px;
  min-height: 300px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.profile-how {
  display: flex;
  justify-content: center;

  padding: 20px;
  margin: 10px;
  max-width: 1620px;
  min-height: 300px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.profile-img {
  width: 200px;
}

.cards {
  display: flex;
  justify-content: center;

  align-items: center;

  width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
}
.page1 {
  text-align: left;
}
.homeText {
  font-size: 20px;
  color: #462a7b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
.list {
  padding: 0;
  margin: 20px;
}
.list li {
  list-style-type: circle;
}
/* .list li:nth-child(2) {
  margin-left: 2%;
}
.list li:nth-child(3) {
  margin-left: 4%;
}
.list li:nth-child(4) {
  margin-left: 6%;
}
.list li:nth-child(5) {
  margin-left: 8%;
} */
.Screenshots {
  /* margin: 2vw; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 0;
}
.Screenshots img {
  display: flex;
  width: 25%;
  height: 54.125%;
  max-height: 400.525px;
  max-width: 185px;
  min-height: 216.5px;
  min-width: 100px;
  flex-shrink: 5;

  margin: 0.6vw;
  -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.payment-details {
  display: flex;

  flex-direction: column;
}
.contactContainer {
  /* background-color: red; */
  width: 90%;
  margin-left: 5%;
  /* margin-top: 5%; */
  height: 100px;
  padding-bottom: 3%;
  bottom: 0;
}
.contactLink {
  text-decoration: none;
  color: #462a7b;
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
}
.contactLink:hover {
  color: black;
}
.payment-cards-register {
  padding-bottom: 30px;
  width: 100%;
  margin-top: 20px;

  /* padding: 20px; */
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.payment-cards-home {
  padding-bottom: 30px;
  max-width: 70%;
  margin-top: 20px;
  margin-left: 15%;
  background-color: white;
  /* padding: 20px; */
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}
.payment-text {
  margin-bottom: 1%;
}
.thighGap {
  width: 5%;
}

.Badge img {
  height: 50px;
  padding-top: 20px;
  margin: 0.5vw;
}

img {
  -ms-interpolation-mode: bicubic;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (min-width: 1920px) {
  .Screenshots img {
    display: flex;
    width: 25%;
    height: 54.125%;
    max-height: 476.3px;
    max-width: 220px;
    min-height: 216.5px;
    min-width: 100px;
    flex-shrink: 5;

    margin: 0.6vw;
    -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
}
@media screen and (max-width: 769px) {
  .navContainer {
    width: 100vw;
    height: 24vw;
    padding-top: 2vw;

    display: flex;
    align-items: center;
  }
  .navLink {
    text-decoration: none;
    color: #462a7b;
    font-family: "Roboto", sans-serif;
    font-size: 5vw;
    font-weight: 500;
  }
  .navLinkCont {
    text-align: right;
    margin-left: 40%;
  }
  .contactLink {
    text-decoration: none;
    color: #462a7b;
    font-family: "Roboto", sans-serif;
    font-size: 3vw;
    font-weight: 400;
  }
  .contactLink:hover {
    color: black;
  }
}
@media screen and (max-width: 429px) {
  .App {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all 0.5s ease;
    margin: 0;
    padding: 0;
  }
  .page1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .homeText {
    font-size: 17px;
    color: #462a7b;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 20px;
    /* background-color: red; */
    text-align: center;
  }
  .list {
    padding: 0;
    margin: 0;
  }
  .list ul {
    margin: 0;
    padding: 0;
  }
  .list li {
    list-style-type: none;
    margin-top: 10px;
  }
  .list li:nth-child(2) {
    padding-left: 0;
    margin-top: 10px;
  }
  .list li:nth-child(3) {
    padding-left: 0;
    margin-top: 10px;
  }

  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    max-width: 500px;
    min-height: 300px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  .profile-img {
    margin-top: 20px;
    width: 150px;
  }

  .navContainer {
    width: 100vw;
    height: 40vw;
    padding-top: 2vw;

    display: flex;
    align-items: center;
  }
  .navLink {
    text-decoration: none;
    color: #462a7b;
    font-family: "Roboto", sans-serif;
    font-size: 7vw;
    font-weight: 500;
  }
  .navLinkCont {
    text-align: right;
    margin-left: 28%;
  }
  .contactLink {
    text-decoration: none;
    color: #462a7b;
    font-family: "Roboto", sans-serif;
    font-size: 5vw;
    font-weight: 400;
  }
  .contactLink:hover {
    color: black;
  }
  .Screenshots {
    margin: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 0;
  }
  .Screenshots img {
    display: flex;

    height: 368.05px;
    width: 170px;

    margin-top: 4vw;
    -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
}
.payment-processing {
  width: 90%;
  margin-top: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin-top: 0;
  margin: 10px;
  border: 10px solid #462a7b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #462a7b transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error-icon {
  font-size: 80px;
  color: red;
  font-weight: bold;
}
h7 {
  font-size: 30px;
  color: red;
}
