.box {
  background-color: rgba(242, 242, 247, 1);
  width: 200px;
  height: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 30px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  transition-duration: 500ms;
}

.box:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(44, 25, 81, 0.8);
  -moz-box-shadow: 0px 0px 5px 0px rgba(44, 25, 81, 0.8);
  box-shadow: 0px 0px 5px 0px rgba(44, 25, 81, 0.8);

  transition-duration: 500ms;
}
b {
  color: red;
  font-size: 18px;
}

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
* Show the overflow in IE.
* 1. Show the overflow in Edge.
*/

button,
input {
  /* 1 */
  overflow: visible;
  border: 0;
}
