@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --primary: rgba(44, 25, 81, 1);

  --primarybg: rgba(242, 242, 247, 1);
  --secondary: rgba(255, 255, 255, 1);
  --primarydark: rgba(28, 28, 30, 1);
  --secondarydark: #8e8e93;
}
.label {
  margin-top: 2%;
  margin-bottom: 1%;
}
.fullBody {
  display: flex;
  /* width: 100vw; */

  justify-content: center;
}
.display-details {
  margin-top: 10px;
}
.payment-text {
  margin-top: 10px;
}
.button {
  margin-top: 5%;
  background-color: var(--primary);
  color: white;
  height: 30px;
  text-align: center;
  border-radius: 3px;
  border: none;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}
.mobileLine {
  display: none;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.label2 {
  margin-bottom: 5%;
}
.accountBar {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6vmax;

  /* identical to box height */
  color: rgba(0, 0, 0, 0.8);
}

.devices {
  width: 100%;
}
.devices h1 {
  margin: 0%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vmax;
  color: #462a7b;
}
.detailContent {
  margin-left: 2%;
  flex: 4;
  min-height: 500px;
}
.sideBar {
  margin-right: 2%;
  margin-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inputContainer div {
  max-width: 1000px;
  flex-grow: 1;
  margin-right: 10px;
}
.inputContainer {
  width: 100%;
  flex-wrap: wrap;
  flex-grow: initial;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.personalDetails h1 {
  margin: 0%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vmax;
  color: #462a7b;
}
.container {
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
  padding-top: 1%;
  padding-bottom: 2%;
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.mainAccountContainer {
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
  padding-top: 1%;
  padding-bottom: 2%;
  width: 100%;
  min-width: 300px;
  max-width: 2000px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
i {
  width: 100%;
  text-align: center;
}

.logo {
  width: 28%;
}
.formContainer {
  width: 90%;

  padding: 2%;
  margin: 2%;
  display: flex;
  flex-direction: column;
}
.accountContainer {
  width: 90%;
  padding: 2%;
  margin: 2%;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.oneLine {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.buttonContainer > Button {
  margin: 10px;
}

.inlineButton {
  /* width: 10%; */
  width: 8vmin;
  height: 4vmin;
  margin-top: 10px;
}
.spacing {
  width: 8%;
  height: 8%;
}
.arrow {
  width: 2%;
  margin-top: 5%;
}
.page2 {
  height: 100vh;
  width: 90vw;
  margin: 0;
  padding: 0;
}
.page1 {
  width: 90vw;
  margin: 0;
  padding: 1vw;
}
.p1IMG {
  height: 100%;
  order: 1;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.aboutCont {
  order: 2;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textBox {
  order: 1;
  margin-left: 5%;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 120%;
  font-weight: 500;
  margin-bottom: 10%;
}
.explain {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: white; */
  display: flex;
  width: 70%;
  height: 70vh;
  padding: 3%;
  margin-top: 5%;

  /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
}
.buttons {
  order: 2;
}
@media screen and (max-width: 429px) {
  .navBar ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .navBar li {
    /* margin-right: 5px; */
    text-align: left;
  }
  .navBar {
    margin: 0;
  }
  .container {
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5%;
    padding-top: 2%;
    padding-bottom: 5%;
    width: 100%;
    min-width: 200px;
    max-width: 1000px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .accountContainer {
    width: 90%;
    padding: 2%;
    margin: 2%;
    display: flex;
    flex-direction: column;

    /* height: 100%; */
  }
  .oneLine {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: baseline;
  }
  .mainAccountContainer {
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2%;
    padding-top: 1%;
    padding-bottom: 2%;
    width: 70%;
    min-width: 300px;
    max-width: 2000px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .accountBar {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 2.5vmax;

    /* identical to box height */
    color: rgba(0, 0, 0, 0.8);
  }
  .log {
    height: 7vh;
    order: 2;
  }
  .devices {
    width: 100%;
  }
  .devices h1 {
    margin: 0%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4vmax;
    color: #462a7b;
  }
  .detailContent {
    margin-left: 2%;

    /* flex: 4; */
  }
  .sideBar {
    margin-right: 2%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    flex: 0;
    flex-grow: 1;
    max-height: 20vmax;
  }
  .inputContainer div {
    max-width: 1000px;

    margin-right: 10px;
  }
  .inputContainer {
    width: 100%;
    flex-wrap: wrap;
    flex-grow: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .personalDetails h1 {
    margin: 0%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4vmax;
    color: #462a7b;
  }
  .mobileLine {
    margin: 15px 0;
    display: inline;
  }
}
.description {
  width: 70%;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 15px;
  font-weight: 500;
  color: green;
}
h3 {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}
h4 {
  font-size: 20px;
 
  color: #462a7b;
}
h7 {
  font-size: 17px;
  color: red;
  font-weight: 600;
}
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.letter {
  font-style: normal;
  font-weight: 600;
}
.pbs {
  width: 12%;
}
.pbsDiv {
  width: 70%;
  margin-top: -3%;
}
.payment-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
h5 {
  margin: 0;
  font-weight: 500;
  color: red;
  font-size: 15px;
}
